@import "../variables";
@import "../button.mixin";

.collapseActions {
  display: flex;
  align-items: center;
  justify-items: flex-start;

  > .button {
    cursor: pointer;
    background-color: transparent !important;

    :global(.fill-color) {
      color: $color-icon-primary;
      fill: $color-icon-primary;
    }
  }
}

.pageWrapper {
  .container .tabContainer {
    margin-top: $spacing-t8;
    :global(.#{$ns}-tab-list) {
      margin-left: 0 !important;
    }
  }

  .actions {
    border-top: 1px solid #e6e9ec;
    margin: $spacing-t4 0 0 0;
    padding: $spacing-t6 0;
    .resetButton {
      @include cancelButton;
      padding: 0 $spacing-t6 !important;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
    }
    .createButton {
      background-color: transparent !important;
      margin-top: $spacing-t7;
      > * {
        margin: 0 !important;
      }

      :global(.fill-color) {
        color: $color-icon-primary;
        fill: $color-icon-primary;
      }

      :global(.#{$ns}-button-text) {
        color: $color-text-primary !important;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-t5;
        line-height: $line-height-t5;
      }
    }
    .saveButton {
      @include actionButton($color-button-bg-primary);
      padding: 0 $spacing-t6 !important;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      margin-right: $spacing-t7;
      :global(.fill-color) {
        color: $color-icon-white;
        fill: $color-icon-white;
      }

      :global(.#{$ns}-button-text) {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .container .targetTypesContainer {
    .title {
      color: $color-text-default;
      font-size: $font-size-t5;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-t5;
      margin: $spacing-t8 0;
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      justify-items: flex-start;
      padding: 0 0 $spacing-t7 0;
      .button {
        @include icon-style-primary;
        color: $color-text-primary !important;
        padding: $spacing-t9 $spacing-t4 !important;
        border-radius: $border-radius-large !important;
        background-image: none !important;
        box-shadow: none !important;
        font-size: $font-size-t5;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-t5;
        :global(.fill-color) {
          color: $color-icon-primary;
          fill: $color-icon-primary;
        }
        &.active {
          background-color: $color-button-bg-primary !important;
          color: $color-text-white !important;
          :global(.fill-color) {
            color: $color-icon-white;
            fill: $color-icon-white;
          }
        }
        &:first-child {
          margin-right: $spacing-t7;
        }
      }
    }

    .contentWrapper {
      padding-top: $spacing-t7;
    }

    .errorList {
      display: flex;
      flex-direction: column;
      > div {
        font-size: $font-size-t5;
        line-height: $line-height-t4;
        color: $color-text-error;
      }
    }
  }
}

.criteriaContainer {
  padding: 0 $spacing-t4;
}
