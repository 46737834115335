@import "variables";

.content {
  :global(.#{$ns}-transition-container) {
    z-index: 99;
  }
  :global(.#{$ns}-popover-content) {
    display: flex;
    align-items: center;
    padding: $spacing-t8 $spacing-t7;
    line-height: $line-height-t4 !important;
    span {
      font-size: $font-size-t5 !important;
      line-height: $line-height-t5 !important;
    }
  }
}

.toolTip {
  margin-right: $spacing-t7;
  display: flex;
  @extend .content;
}
