@import "variables";
@import "form.mixin.scss";
@import "label.mixin.scss";
.inputLabel {
  margin: 0px !important;
  flex-direction: column !important;
  align-items: initial !important;
}

.label {
  @include label;
  > span {
    line-height: $spacing-t6 !important;
    font-size: $font-size-t6;
    color: $color-grey-040;
    padding-bottom: $spacing-t9;
    margin-left: $spacing-t9;
  }
}

.numericInput,
.inputField {
  display: flex;
  position: relative;
  align-items: center;
  input {
    @include inputStyle();
    height: $spacing-t3 !important;
  }
  > svg:nth-child(1) {
    position: absolute;
    z-index: 1;
  }

  &.withRightIcon {
    input {
      padding-right: $spacing-t3 !important;
    }
  }

  &.error {
    input {
      border-color: $color-red-080;
    }
  }
  :global(.#{$ns}-input-action) {
    height: 100%;
    right: 5px !important;
  }
}

.clearSelection {
  background: none !important;
  display: flex;
  align-items: center;
  height: 100%;
  > div {
    padding: 0 !important;
  }
}

.numericInput {
  :global(.#{$ns}-input-group) {
    width: 100%;
  }
}
