@import "../variables";
@import "../button.mixin";

.container {
  .title {
    color: $color-text-default;
    font-size: $font-size-t5;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-t5;
    margin: $spacing-t8 0;
  }

  .contentWrapper {
    display: flex;
    justify-items: flex-start;
  }

  .contentWrapper .selector {
    flex: 2;
    margin-right: $spacing-t6;
    display: flex;
    flex-direction: column;
  }

  .contentWrapper .criteriaInput {
    display: flex;
    align-items: flex-end;
    justify-items: flex-start;

    :global(.#{$ns}-button) {
      min-width: 350px !important;
    }
  }

  .criteriaTextInput {
    :global(.#{$ns}-input) {
      min-width: 350px !important;
    }
  }

  .contentWrapper .actions {
    flex: 6;
    align-self: flex-end;
  }
}

.include {
  @include includeButtonWithIcon;
}

.exclude {
  @include excludeButtonWithIcon;
}
