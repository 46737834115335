@import "variables";
@import "text-ellipsize.mixin";

.container {
  display: flex;
  align-items: center;
  margin-top: $spacing-t6;
  user-select: none;
  color: $color-grey-030;
  min-height: 48px;
  padding: $spacing-t7 $spacing-t7;
  border-left: 4px solid;
  border-color: transparent;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;

    .navIcon {
      min-width: $spacing-t4;
      min-height: $spacing-t4;
    }

    .name {
      color: $color-grey-030;
      font-family: $font-family;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      font-weight: $font-weight-semi-bold;
      padding: $spacing-t10 0;
      text-transform: capitalize;
      @include text-ellipsize;
    }
  }
  :global(.fill-color) {
    color: $color-grey-030;
    fill: $color-grey-030;
  }

  &.active {
    border-color: $color-icon-white;

    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }

    .navIcon {
      color: $color-icon-white;
    }

    .name {
      color: $color-text-white;
    }
  }

  &.headerExpanded {
    padding: 0;
    .contentWrapper {
      user-select: none;
      flex-direction: row;
      justify-content: flex-start;
    }

    .navIcon {
      margin-right: $spacing-t7;
    }
  }
}
