@import "variables";

.menu {
  color: $color-text-default !important;
  font-size: $font-size-t5 !important;
  max-width: none !important;
  width: 100% !important;
  position: relative;
  min-width: auto !important;

  &.error {
    :global(.#{$ns}-multi-select) {
      border-color: $color-red-060 !important;
    }
  }

  // This is in both Suggest and Other menu items
  :global(.#{$ns}-menu),
  &:global(.#{$ns}-menu) {
    max-height: 30vh;
    overflow: scroll;
  }

  :global(.#{$ns}-transition-container) {
    width: 100% !important;
  }

  :global(.#{$ns}-menu-item) {
    color: $color-text-default !important;
    &:hover {
      background-color: $color-grey-020;
    }
    &:global(.#{$ns}-active) {
      box-shadow: none !important;
      background-color: $color-primary-010 !important;
      color: $color-text-default !important;
      &:hover {
        background-color: $color-primary-020;
      }
    }
  }
  :global(.#{$ns}-icon-tick) {
    color: $color-icon-primary !important;
  }
  :global(.#{$ns}-icon-small-tick) {
    color: $color-icon-primary !important;
  }
}

.menuItem {
  &:hover {
    background-color: $color-primary-020;
  }
  > div {
    margin-right: 0;
  }
}

.popOver {
  box-shadow: $shadow-card !important;
  width: 100%;
}
