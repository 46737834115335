@import "../variables";
@import "../button.mixin";

.tabPanel {
  display: flex;

  > :nth-child(1) {
    flex: 3;
    padding-right: $spacing-t6;
  }

  .tabPanel {
    padding: $spacing-t6 $spacing-t4 $spacing-t6 0;
  }

  > :nth-child(3) {
    flex: 1;
  }
}
