@import "./variables";

.container {
  display: flex;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: $color-text-primary;
  background-color: $color-button-bg-default;
  border-radius: $border-radius-large;
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  min-width: 40px;
  padding: $spacing-t9;
  margin: 0 $spacing-t7 0 0;

  :global(.fill-color) {
    color: $color-icon-primary !important;
    fill: $color-icon-primary !important;
  }
}

.title {
  font-size: $font-size-t5;
  color: $color-text-default;
  font-weight: $font-weight-semi-bold;
  margin-bottom: $spacing-t9;
}

.body {
  font-size: $font-size-t4;
  font-weight: $font-weight-semi-bold;
}
