@import "variables";
@import "text-ellipsize.mixin";

.menuItemSelected,
.menuItemSelected:hover {
  background-color: $color-primary-010 !important;
  border: 1px solid $color-primary-060 !important;
  > div {
    color: $color-text-default !important;
  }
}

.container {
  margin: 0 !important;

  :global(.#{$ns}-transition-container) {
    width: 100% !important;
  }

  :global(.#{$ns}-form-content) {
    width: 100%;
    position: relative;
  }

  :global(.#{$ns}-label) {
    font-weight: bold;
    font-size: 12px;
    color: $color-text-secondary;
    line-height: 33px !important;
  }

  .button {
    background: $color-white !important;
    border: 1px solid $color-border;
    border-radius: $border-radius-medium;
    width: 100%;
    justify-content: initial;
    max-height: 32px;
    > * {
      width: 100%;
      text-align: left !important;
    }
    > div > div:nth-child(1) {
      flex: 2;
      display: inline-block;
      font-size: $font-size-t5;
      font-weight: $font-weight-regular;
      line-height: $line-height-t5;
      color: $color-grey-080;
      @include text-ellipsize;
    }
  }

  &.placeHolderColor {
    .button > div > div:nth-child(1) {
      color: $color-text-placeholder !important;
    }
  }

  &.error {
    .button {
      border: 1px solid $color-red-060 !important;
    }
  }
}

.controls {
  margin: 0 $spacing-t7;
}
