@import "../variables";
@import "../text-ellipsize.mixin";

.tabMenu {
  float: left;
  width: 23%;
  height: 72vh;
  overflow: scroll;
  margin-right: $spacing-t6;
  padding: $spacing-t9;
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $shadow-default;

  .tabItems {
    border-radius: $border-radius-medium;
    align-items: center;
    padding: $spacing-t7/2 $spacing-t7;
    margin-bottom: $spacing-t7;
    color: $color-text-default;
    border: 1px solid transparent;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    cursor: pointer;
    @include text-ellipsize;
  }

  :hover {
    background-color: $color-button-bg-default;
  }

  .isActive {
    border: 1px solid $color-primary-border;
    font-weight: $font-weight-semi-bold;
    background-color: $color-button-bg-primary-3;
  }
}

.settingDetails {
  width: 75%;
  float: left;
}
