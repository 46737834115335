@import "variables";
@import "form.mixin.scss";
@import "label.mixin.scss";
@import "icon.mixin.scss";

.input {
  display: block !important;
  :global(.#{$ns}-label) {
    @include label;
    padding: 0 !important;
  }
}

.textArea {
  @include inputStyle();
}

.errorMessage {
  font-size: $font-size-t5;
  color: $color-text-error;
  padding-top: $spacing-t9;
}

.createLink {
  font-size: $font-size-t5;
  color: $color-text-primary;
  display: inline-block;
  &:hover {
    color: $color-text-secondary;
  }
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include label;
  padding-bottom: $spacing-t9;
}

.title:empty {
  display: none;
}

:global(.#{$ns}-popover-target) {
  width: 100% !important;
  :global(.#{$ns}-form-content) {
    width: 100% !important;
  }
}

.formgroup {
  margin-bottom: $spacing-t6;
}

.radioGroup {
  display: flex !important;
}

.radioButton {
  align-items: center;
  padding-left: $spacing-t8;
  font-size: $font-size-t5;
  color: $color-text-default;
  line-height: $line-height-t4;
  :global(input:checked ~ .#{$ns}-control-indicator) {
    outline: none !important;
    background-color: $color-button-bg-primary !important;
  }
}

.childContainer {
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 0 !important;
}

.button {
  @include icon-style-primary;
  padding: $spacing-t9 $spacing-t8 !important;
}

// Globals
input,
:global(.#{$ns}-input) {
  user-select: all !important;
}

// Date Picker Global
:global(.#{$ns}-datepicker) {
  box-shadow: $shadow-card;
}
