@import "../variables";
@import "../form.mixin.scss";
@import "../button.mixin.scss";

.mergeButton {
  @include buttonWithIcon($color-button-bg-primary);
}

.tabContainer {
  padding: 0;
}

.downIcon {
  :global(.fill-color) {
    fill: #9799bd !important;
  }
}

.rightIcon {
  margin-left: $spacing-t9;
}

.actionCell {
  padding: $spacing-t8 $spacing-t7 !important;
}

.innerTable {
  background-color: $color-tables-zebra-darkest !important;
}

.moreInfoText {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 24px;
    width: auto;
    display: inline-block;
  }
}

.spaceAround {
  padding: $spacing-t6 $spacing-t4;
  margin-bottom: $spacing-t6;
  align-items: center;
}
