@import "variables";

.header {
  align-items: center;
}

.headerIcon {
  display: flex;
  align-items: center;
  height: 32px;
  color: $color-text-primary;
  background-color: $color-button-bg-default;
  border-radius: $border-radius-large;
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  min-width: 32px;
  padding: $spacing-t9;
  margin: 0 $spacing-t7 0 0;

  :global(.fill-color) {
    color: $color-icon-primary !important;
    fill: $color-icon-primary !important;
  }
}

.headerText {
  color: $color-text-default;
  line-height: $line-height-t1;
}

.badgeContainer {
  display: flex;
  align-items: center;
  height: $line-height-t1;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: $color-text-primary;
  background-color: $color-button-bg-default;
  border-radius: 32px / 100% !important; // Sets an additional radius, so we can have elliptical corners
  font-size: $font-size-t5;
  font-weight: $font-weight-semi-bold;
  min-width: 32px;
  padding: $spacing-t9 $spacing-t8;
  margin: 0 $spacing-t8;

  &.active {
    color: $color-text-white;
    background-color: $color-button-bg-green;
  }
}

.activeCountLabel {
  color: $color-text-success;
  margin-right: $spacing-t6;
}

.tagLine {
  color: $color-text-secondary;
  font-size: $font-size-t5;
  line-height: $line-height-t4;
  margin-top: 0.2rem;
}

.pageHeader {
  align-items: center;
  padding: $spacing-t6 $spacing-t4;
  background: $color-background-white;
  box-shadow: $shadow-default;
}

.headerButtonContainer {
  align-items: center !important;
}

@media only screen and (max-width: 680px) {
  .pageHeader {
    margin-bottom: 0;
    .header {
      padding: 0;
      margin-bottom: $spacing-t7 !important;
    }
    button {
      padding: 0;
    }
  }
}
