@import "../variables";
@import "../icon.mixin";
@import "../button.mixin";

.container {
  padding-bottom: $spacing-t6;
  border-bottom: 1px solid $color-border;
  display: flex;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: $spacing-t7;

  > * {
    margin-left: $spacing-t8;
  }
  .createButton {
    background-color: transparent !important;
    margin-top: $spacing-t7;
    > * {
      margin: 0 !important;
    }

    :global(.fill-color) {
      color: $color-icon-primary;
      fill: $color-icon-primary;
    }

    :global(.#{$ns}-button-text) {
      color: $color-text-primary !important;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
    }
  }
  .resetButton {
    @include cancelButton;
    padding: 0 $spacing-t6 !important;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
  }

  .saveButton {
    @include actionButton($color-button-bg-primary);
    padding: 0 $spacing-t6 !important;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    margin-right: 0;
    :global(.fill-color) {
      color: $color-icon-white;
      fill: $color-icon-white;
    }

    :global(.#{$ns}-button-text) {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.container .stepContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: not-allowed;
    pointer-events: none;
    .icon {
      display: flex;
      align-items: center;
      padding: $spacing-t9 !important;
      border-radius: $border-radius-large;
      vertical-align: middle;
      background-color: $color-button-bg-primary;
      :global(.fill-color) {
        color: $color-icon-white;
        fill: $color-icon-white;
      }
    }

    .name {
      color: $color-text-default;
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      text-align: center;
      padding-left: $spacing-t8 !important;
    }

    &.active {
      cursor: not-allowed !important;
      pointer-events: none !important;
      .name {
        font-weight: $font-weight-bold;
      }

      .icon {
        background-color: $color-icon-primary;
        padding: $spacing-t7/2 !important;
        :global(.fill-color) {
          color: $color-icon-white;
          fill: $color-icon-white;
        }
      }
    }

    &.valid {
      cursor: pointer;
      pointer-events: all;
    }
  }

  .separator {
    background-color: $color-border;
    width: 24px;
    height: 2px;
    margin: 0 $spacing-t7;

    &:last-child {
      display: none;
    }
  }
}
